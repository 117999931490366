import React, {FC, useEffect, useMemo} from 'react';
import { useAppContext } from '../context/AppContext';
import { Column, Row } from '../atoms/Layout';
import Loading from '../atoms/Loading';
import DateRangeInput from '../atoms/DateRangeInput';
import moment from 'moment';
import FeedbackFilters from '../organisms/FeedbackFilters';
import { FeedbackFilter, filterFeedback } from '../model/feedback';
import FeedbackItem from '../organisms/FeedbackItem';
import {FeedbackResponse} from "../api/feedback";

interface ScrollableData<T> {
    data: T[];
    nextSlice: () => void;
    visibleCount: number;
}

const useScrolling = (data: FeedbackResponse[]): ScrollableData<FeedbackResponse> => {
    const [value, setValue] = React.useState<ScrollableData<FeedbackResponse>>({ data: [], nextSlice: () => {}, visibleCount: 0});

    const update = (allData: FeedbackResponse[], visibleCount: number) => {
        if (visibleCount >= allData.length) {
            visibleCount = allData.length;
        }

        const newValue = {
            data: allData.slice(0, visibleCount),
            nextSlice: () => {
                update(allData, visibleCount + 50);
            },
            visibleCount
        }

        setValue(newValue);
    }

    useEffect(() => {
        update(data, 50);
    }, [data]);

    return value
};

const FeedbackViewer: FC = () => {
    const { feedback, channels } = useAppContext()
    const [filter, setFilter] = React.useState<FeedbackFilter>({
        startDate: moment().subtract(30, 'days').startOf('day'),
        endDate: moment().endOf('day'),
    })
    const filteredFeedback = useMemo(() => filterFeedback(feedback.value ?? [], filter).sort((a, b) => {
        return -moment(a.createdAt).diff(moment(b.createdAt));
    }), [feedback.value, filter])
    const { data: streamingFeedback, nextSlice } = useScrolling(filteredFeedback)
    const scrollableRef = React.useRef<HTMLDivElement>(null)

    useEffect(() => {
        void feedback.load()
        void channels.load()
    }, [])



    useEffect(() => {
        const handleScroll = () => {
            if (!scrollableRef.current) {
                return;
            }

            const scrollable = scrollableRef.current;
            const nearBottom = scrollable.scrollTop + scrollable.clientHeight >= scrollable.scrollHeight - 100;
            if (nearBottom) {
                nextSlice();
            }
        };

        if (!scrollableRef.current) {
            return;
        }
        scrollableRef.current.addEventListener('scroll', handleScroll);

        return () => {
            if (!scrollableRef.current) {
                return;
            }
            scrollableRef.current.removeEventListener('scroll', handleScroll);
        }
    }, [scrollableRef.current, nextSlice]);

    if (feedback.loading || channels.loading) {
        return <Row center><Loading size="large" /> </Row>
    }

    return <Column size="full" gap="large">
        <Row>
            <DateRangeInput startDate={filter.startDate} endDate={filter.endDate} onChange={(startDate, endDate) => {
                setFilter({
                    ...filter,
                    startDate: startDate,
                    endDate: endDate,
                })
            }} />
        </Row>
        <Row gap="large">
            <FeedbackFilters filter={filter} setFilter={setFilter} feedback={feedback.value ?? []} channels={channels.value ?? []} />
            <Column size="full" gap="large" style={{ overflow: 'auto', maxHeight: 'calc(100vh - 240px)' }} ref={scrollableRef}>
                { streamingFeedback.map((feedback) => {
                    return <FeedbackItem key={feedback.id} feedback={feedback} channels={channels.value ?? []} />
                })}
            </Column>
        </Row>
    </Column>
}

export default FeedbackViewer;
