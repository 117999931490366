import axios from 'axios';
import {Topic} from "./scoring";

export interface TextFeedbackSubmission {
    text: string
    promoterScore?: number
}

export interface VoiceNoteFeedbackSubmission {
    blob: Blob,
    anonymize: boolean
    promoterScore?: number
}

export type FeedbackSubmission = TextFeedbackSubmission | VoiceNoteFeedbackSubmission

export enum Emotion {
    HAPPY = 'HAPPY',
    SAD = 'SAD',
    NEUTRAL = 'NEUTRAL',
    ANGRY = 'ANGRY'
}

// The AI only supports these genders
export enum Gender {
    MALE = 'MALE',
    FEMALE = 'FEMALE'
}

type LanguageCode = string;

export interface AnalysisResult {
    textContent: string
    sentimentScore: number // -1 to 1
    age?: number
    emotion?: Emotion
    gender?: Gender
    topics: Topic[],
    language: LanguageCode
}


export enum FeedbackResponseType {
    TEXT = 'TEXT',
    VOICE = 'VOICE',
}

export interface FeedbackResponse {
    id: string
    spaceId: string
    channelId: string
    createdAt: string
    promoterScore: number | null,
    type: FeedbackResponseType,
    analysis: AnalysisResult
}

const getPresignedUploadUrl = async (): Promise<{ url: string, key: string }> => {
    const response = await axios.get(`/api/public/feedback/upload`)
    console.log(response.data)
    return response.data
}

const performUpload = async (data: Blob): Promise<string> => {
    const { url, key } = await getPresignedUploadUrl()
    console.log("pu", { url, key })
    await axios.put(url, data, { headers: { 'Content-Type': 'binary/octet-stream' } })
    return key
}

export const submitFeedback = async (channelId: string, submission: FeedbackSubmission): Promise<void> => {
    if ('text' in submission) {
        await axios.post(`/api/public/feedback/${channelId}/text`, { text: submission.text, promoterScore: submission.promoterScore })
    } else {
        const uploadKey = await performUpload(submission.blob)
        const data = {
            voice: uploadKey,
            anonymize: submission.anonymize,
            promoterScore: submission.promoterScore
        }
        await axios.post(`/api/public/feedback/${channelId}/voice`, data)
    }
}

export const getAllFeedback = async (): Promise<FeedbackResponse[]> => {
    const response = await axios.get(`/api/feedback`)
    return response.data
}

export const getFeedbackAudio = async (feedbackId: string): Promise<Blob> => {
    const response = await axios.get(`/api/feedback/${feedbackId}/audio`, { responseType: 'blob' })
    return response.data
}

export const anonymizeAudio = async (blob: Blob, format: 'ogg' | 'webm'): Promise<Blob> => {
    const uploadKey = await performUpload(blob)
    const url = format === 'webm' ? `/api/public/feedback/anonymize/webm` : `/api/public/feedback/anonymize`
    const response = await axios.post(url, { voice: uploadKey }, { responseType: 'blob' })
    return response.data
}
