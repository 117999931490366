import React, { FC } from 'react';
import { Column, Row } from 'atoms/Layout';
import Logo from 'atoms/Logo';
import {Body, Subtitle, Title} from 'atoms/Typography';
import Panel from "../atoms/Panel";
import {VerticalSpace2} from "../atoms/Space";

const TermsOfService: FC = () => {
    return <Column size="full" padding="large" gap="medium" center>
        <Panel style={{ width: 1200, maxWidth: "calc(100vw - 80px)"}}>

            <Column gap="medium">
                <Row vcenter gap="large">
                    <Logo />
                    <Title>Symantiq App Terms of Use</Title>
                </Row>
                <VerticalSpace2 />
                <Subtitle>1. Introduction</Subtitle>
                <Subtitle>1.1 Agreement to Terms</Subtitle>
                <Body>By accessing and using this application ("App"), you confirm that you accept these Terms of Use
                    and agree to comply with them. If you do not agree to these Terms of Use, you must not use our App.
                    We recommend that you save a copy of these terms for future reference.</Body>
                <Subtitle>1.2 Who We Are</Subtitle>
                <Body>Symantiq Limited ("we", "us", "our") is registered in England and Wales under company number
                    14395687, with its registered office at Suite 1, Marina Court, Castle Street, Hull, England,
                    HU1 1TJ and main trading address at Wework, 184 Shepherds Bush Rd, London W6 7NL.</Body>
                <Subtitle>2. Feedback Features and Voice Data</Subtitle>
                <Subtitle>2.1 Voice Feedback</Subtitle>
                <Body>
                    When providing feedback through our App, you acknowledge and agree that:
                </Body>
                <Body>
                    <ul>
                        <li>Your voice will be modified to keep your feedback anonymous</li>
                        <li>Voice modification is an automated process designed to remove identifying characteristics</li>
                        <li>Modified voice recordings may be used for analysis and service improvement</li>
                        <li>You have the option to provide feedback in text format instead of voice</li>
                    </ul>
                </Body>
                <Subtitle>2.2 Consent for Voice Processing</Subtitle>
                <Body>By using the voice feedback feature, you explicitly consent to the recording of your voice; the
                    modification and processing of your voice recording; the storage and analysis of the modified
                    recording; the sharing of anonymised feedback with the relevant service provider. </Body>
                <Subtitle>2.3 Anonymity and Data Protection</Subtitle>
                <Body>We implement the following measures to protect your privacy:</Body>
                <Body>
                    <ul>
                        <li>Voice recordings are encrypted during transmission and storage</li>
                        <li>Access to voice recordings is strictly controlled</li>
                        <li>Voice modification occurs before any human review</li>
                        <li>Original voice recordings are deleted after modification</li>
                        <li>No attempt will be made to reverse the voice modification</li>
                    </ul>
                </Body>
                <Subtitle>3. Contact Information Collection</Subtitle>
                <Subtitle>3.1 Optional Contact Details</Subtitle>
                <Body>If you choose to provide contact information for follow-up you agree that this is entirely
                    voluntary. your contact details will be stored separately from your feedback, contact information
                    will only be used for the purpose of responding to your feedback and you can request deletion
                    of your contact information at any time.</Body>
                <Subtitle>3.2. Third-Party Contact Pages</Subtitle>
                <Body>If you are redirected to a service provider's contact page you agree that you will be subject to
                    their privacy policy and terms, any data you provide will be collected directly by them, we are not
                    responsible for their data collection practices and we recommend reviewing their privacy policy
                    before providing information</Body>
                <Subtitle>4. User Obligations</Subtitle>
                <Subtitle>4.1 Acceptable Use</Subtitle>
                <Body>You agree to use the App only for lawful purposes and in a way that does not infringe any third
                    party's rights, submit false or misleading feedback, attempt to bypass voice modification features,
                    share personal information about others, or use the App in any fraudulent way.</Body>
                <Subtitle>4.2 Prohibited Activities</Subtitle>
                <Body>You must not and we agree that we will not attempt to identify individuals from modified voice
                    recordings. You will also not use automated systems to submit feedback, interfere with the proper
                    working of the App, circumvent security measures or submit harmful or malicious content.</Body>
                <Subtitle>5. Technical Requirements and Security</Subtitle>
                <Subtitle>5.1 Device Requirements</Subtitle>
                <Body>You are responsible for ensuring the device upon which you submit your feedback meets minimum
                    requirements. You must keep your device secure and free from malware and you should use up-to-date
                    security software.</Body>
                <Subtitle>5.2 Access and Security</Subtitle>
                <Body>You agree not to share your access credentials with anyone. You agree to notify us immediately
                    of any security concerns and we may suspend access if we suspect security issues.</Body>
                <Subtitle>6. Privacy and Data Protection</Subtitle>
                <Subtitle>6.1 Data Processing</Subtitle>
                <Body>We process your data in accordance with the UK General Data Protection Regulation, The Data
                    Protection Act 2018 and our Privacy Notice.</Body>
                <Subtitle>7. Changes and Updates</Subtitle>
                <Body>We may update these terms at any time to reflect changes in our practices, comply with legal
                    requirements, improve service functionality, address new features or services or for any other
                    business reason we deem necessary. Significant changes will be notified through the App and your
                    continued use after the changes are implemented constitutes your express acceptance. We recommend
                    you regularly review the terms. </Body>
                <Subtitle>8. Liability and Disclaimers</Subtitle>
                <Subtitle>8.1 Limited Liability</Subtitle>
                <Body>We are not liable for technical issues beyond our control, the accuracy of third-party content,
                    loss of data during transmission that is outside of our control, and indirect or consequential
                    losses</Body>
                <Body>We do not guarantee uninterrupted service. We may suspend or withdraw the App at any time and we
                    are not liable for service interruptions.</Body>
                <Subtitle>9. Governing Law and Jurisdiction</Subtitle>
                <Body>These terms are governed by English law. Both parties submit to the exclusive jurisdiction of the
                    English courts.</Body>
                <Subtitle>10. Contact Us</Subtitle>
                <Body>For questions about these Terms of Use, please contact us:</Body>
                <Body>
                    <ul>
                        <li>Email: <a href="mailto:dpo@symantiq.com">dpo@symantiq.com</a></li>
                        <li>Post: Symantiq AI, Wework, 184 Shepherds Bush Rd, London W6 7NL</li>
                        <li>For the attention of: Legal Department</li>
                    </ul>
                </Body>

            </Column>
        </Panel>
    </Column>
}

export default TermsOfService;
