
import React, { FC } from 'react';
import { Column, Row } from 'atoms/Layout';
import Logo from 'atoms/Logo';
import {Body, BodyBold, LabelBold, Subtitle, Title} from 'atoms/Typography';
import Panel from "../atoms/Panel";
import {VerticalSpace2} from "../atoms/Space";

const PrivacyPolicy: FC = () => {
    return <Column size="full" padding="large" gap="medium" center>
        <Panel style={{ width: 1200, maxWidth: "calc(100vw - 80px)"}}>

            <Column gap="medium">
                <Row vcenter gap="large">
                    <Logo />
                    <Title>Symantiq Privacy Notice - Feedback</Title>
                </Row>
                <VerticalSpace2 />
                <Subtitle>Privacy Notice</Subtitle>
                <Body>Last Updated: December 2024</Body>
                <Body>This Privacy Notice and the App Terms of Use (the "Terms") together form the complete agreement
                    regarding how your written and/or audio feedback is processed under our data practices. </Body>
                <VerticalSpace2 />
                <Subtitle>Background</Subtitle>
                <Body>Symantiq is a limited company registered with Companies House with a registered company number of
                    14395687 and is based in the United Kingdom.</Body>
                <Body>Symantiq Limited understands that your privacy is important to you, and you care about how your
                    personal data is used. We respect and value the privacy of all of our customers, and we will only
                    collect and use personal data in ways that are described here and consistent with our obligations
                    and your rights under Data Protection laws.</Body>
                <VerticalSpace2 />
                <Subtitle>What Does This Notice Cover?</Subtitle>
                <Body>This Privacy Notice informs you of how Symantiq (collectively, “we”, “our”, “us” or “Symantiq”)
                    collects and uses your personal data. It applies to the personal data that you provide directly to
                    us. </Body>
                <Body>It is applicable where you decide to leave your personal data in order to provide feedback to one
                    of our clients who you may have relationship with or have used one of our client’s products or
                    services and would like to let them know of your experience.</Body>
                <Body>Symantiq allows you to share your experience by either leaving a written summary or by recording
                    your experience vocally.</Body>
                <Body>If you have any questions about this Privacy Notice, you can contact us using the details set out
                    in the Contact Us section.</Body>
                <Body>When reading this Privacy Notice, you should also read the App Terms of Use which can be found
                    at the bottom of our feedback page.</Body>
                <VerticalSpace2 />
                <Subtitle>What is Personal Data and What Personal Data Does Symantiq Collect?</Subtitle>
                <Body>
                    For the purposes of the services Symantiq provides and the governing legislation Symantiq adheres
                    to, Personal data is deﬁned by the UK GDPR and the Data Protection Act 2018 as ‘any information
                    relating to an identiﬁable person who can be directly or indirectly identiﬁed in particular by
                    reference to an identiﬁer’.</Body>
                <Body>
                    What this means is that any information which you provide us with which allows us to identify you
                    is called personal data.</Body>
                <Body>
                    Where you leave a written summary of your experience, we may be able to deduce who you are. For
                    example, by providing us with your name and telling us your birthday was last week, this may enable
                    us to identify not only your name, but the year and month in which you were born.</Body>
                <Body>
                    Another example would be where you leave an audio summary of your experience and provide us with
                    your name and also inform us that your birthday was last week. Not only can we identify your name
                    and the year and month in which you were born, we may be able to deduce whether your emotional
                    state is based on your sound, tempo, and other variables. This is called biometric data and falls
                    under the definition of Special Categories Personal Data or Sensitive Data.
                </Body>
                <VerticalSpace2/>
                <Subtitle>Your Personal Data Rights?</Subtitle>
                <Body>
                    Under Data Protection Legislation, you can exercise the following rights where applicable:
                </Body>
                <Body>
                    <ul>
                        <li>
                            <strong>Request access to your Personal Data</strong> - This enables you to receive a copy
                            of the personal data Symantiq holds about you;
                        </li>
                        <li>
                            <strong>Request correction of your Personal Data</strong> - This enables you to have any
                            incomplete or inaccurate data we hold about you corrected;
                        </li>
                        <li>
                            <strong>Request erasure of your Personal Data</strong> - This enables you to ask us to
                            delete or remove personal data where there is no good reason for us continuing to process
                            it (Note, however, that we may not always be able to comply with your request of erasure
                            for specific legal reasons which will be notified to you, if applicable, at the time of
                            your request);
                        </li>
                        <li>
                            <strong>Object to processing of your Personal Data</strong> - where we are relying on a
                            legitimate interest (or those of a third party) and there is something about the processing
                            of your personal information which makes you want to object to processing on this ground as
                            you feel it impacts on your fundamental rights and freedoms;
                        </li>
                        <li>
                            <strong>Request restriction of processing of your Personal Data</strong> - This enables you
                            to ask us to suspend the processing of your personal data;
                        </li>
                        <li>
                            <strong>Withdraw consent</strong> - This means that, if we are relying on your consent as
                            the legal basis for using your personal data, you are free to withdraw that consent at any
                            time.
                        </li>
                        <li>
                            <strong>The right to data portability</strong>  - Where you have provided personal data to
                            us directly, we are using it with your consent or for the performance of a contract, and
                            that data is processed using automated means, you can ask us for a copy of that personal
                            data to re-use with another service or business in many cases.
                        </li>
                        <li>
                            <strong>Rights relating to automated decision-making and profiling</strong> – Where we
                            analyse audio feedback and draw conclusions from this.
                        </li>
                    </ul>
                </Body>
                <VerticalSpace2 />
                <Subtitle>Security Requirements and Technical Measures</Subtitle>
                <Body>In addition to our general security practices, we implement the following specific technical and
                    organisational measures to protect your personal data:</Body>
                <Body>
                    <ul>
                        <li>
                            Access Controls - ensuring only the relevant and appropriate persona have access to
                            personal data and only for the purposes intended.
                        </li>
                        <li>
                            Security Monitoring - audit logs are kept and examined, and incident response procedures
                            are in place.
                        </li>
                        <li>
                            Data Security - A number of encryption tools are in place to protect data and minimal data
                            is kept.
                        </li>
                        <li>
                            Data Loss Prevention - regular monitoring is in place with practices in place to eliminate
                            unauthorised behaviours.
                        </li>
                        <li>
                            Technical Controls – Embedded into our systems are firewalls, antivirus and malware
                        </li>
                    </ul>
                </Body>
                <VerticalSpace2 />
                <Subtitle>How Is Personal Data Used?</Subtitle>
                <Body>
                    We will only use your personal data for the purpose(s) for which it was originally collected unless
                    we reasonably believe that another purpose is compatible with that or those original purpose(s) and
                    need to use your personal data for that purpose. If we do use your personal data in this way and you
                    wish us to explain how the new purpose is compatible with the original, please contact us using the
                    details provided.
                </Body>
                <Body>
                    In some circumstances, where permitted or required by law, we may process your personal data
                    without your knowledge or consent. This will only be done within the bounds of the Data Protection
                    Legislation and your legal rights.
                </Body>
                <VerticalSpace2 />
                <Subtitle>How Long Is Personal Data Kept?</Subtitle>
                <Body>
                    We will not keep your personal data for any longer than is necessary in order to meet the reason(s)
                    for which it was first collected.
                </Body>
                <Body>
                    We have an internal policy which governs the retention of your personal data and it will not be kept
                    any longer unless required by law or due to legal proceedings where we have been instructed by a
                    Court to retain such data.
                </Body>
                <VerticalSpace2 />
                <Subtitle>How and Where is Personal Data Stored?</Subtitle>
                <BodyBold>Data Storage Locations</BodyBold>
                <Body>
                    Your personal data will be primarily processed and stored on Servers operated by Amazon Web Services
                    (AWS), our Cloud Service Provider. This means your data may be stored in various locations, including:
                </Body>
                <Body>
                    <ul>
                        <li>The United States of America</li>
                        <li>The European Economic Area (EEA)</li>
                        <li>The United Kingdom</li>
                    </ul>
                </Body>
                <BodyBold>International Data Transfers</BodyBold>
                <Body>Where your personal data is transferred outside the UK or EEA, particularly to the United States, we
                    ensure appropriate safeguards are in place to protect your data. These safeguards include:</Body>
                <LabelBold>AWS Specific Safeguards</LabelBold>
                <Body>We rely on AWS's compliance with approved transfer mechanisms, including:</Body>
                <Body>
                    <ul>
                        <li>Standard Contractual Clauses (SCCs) approved by the European Commission and the UK Information
                            Commissioner's Office</li>
                        <li>AWS's robust technical and organizational security measures</li>
                        <li>AWS's certification under recognized security standards and frameworks</li>
                    </ul>
                </Body>
                <LabelBold>Additional Protection Measures</LabelBold>
                <Body>We implement additional measures to protect your data during international transfers:</Body>
                <Body>
                    <ul>
                        <li>Encryption of data in transit and at rest</li>
                        <li>Access controls and authentication requirements</li>
                        <li>Regular security assessments and monitoring</li>
                        <li>Data minimisation practices to ensure only necessary data is transferred</li>
                    </ul>
                </Body>
                <VerticalSpace2 />
                <Subtitle>Contact Us</Subtitle>
                <Body>If you have any question relating to your Personal Data, including making a subject access request,
                    please use the following details: </Body>
                <Body>
                    <a href={"mailto:dpo@symantiq.com"}>dpo@symantiq.com</a>
                </Body>
                <VerticalSpace2 />
                <Subtitle>Changes to this Privacy Notice</Subtitle>
                <Body>This Notice will be updated from time to time and is necessary in order to meet Data Protection
                    legislative requirements and also to inform you of any changes within our business structure and/or
                    processing.</Body>
            </Column>

        </Panel>
    </Column>
}

export default PrivacyPolicy;
