import {REPORT_CSS} from "./reportCss";

interface Action {
    title: string;
    description: string;
}

interface ActionPlan {
    title: string;
    introduction: string;
    sections: {
        title: string;
        immediateActions: Action[];
        nextLevelActions: Action[];
        impact?: string;
    }[];
    conclusion: string;
}

interface ResponseProfile {
    overallSentiment: string;
    negativeResponses: number;
    neutralResponses: number;
    positiveResponses: number;
}

interface Score {
    title: string;
    score: number;
    basedOn: string;
    description: string;
    color: 'negative' | 'neutral' | 'positive';
}

interface ScoreIndex {
    title: string;
    description: string;
    scores: Score[];
}

interface KeyTopicsChart {
    type: 'keyTopics';
    description: string;
    totalResponses: number;
    topics: {
        title: string;
        responses: number;
    }[]
}

interface TopicSentimentChart {
    type: 'topicSentiment';
    description: string;
    topics: {
        title: string;
        negativeResponses: number;
        neutralResponses: number;
        positiveResponses: number;
    }[]
}

interface BarChart {
    type: 'bar';
    title: string;
    labelStyle?: 'horizontal' | 'angled'
    data: {
        label: string;
        value: number;
        color: 'negative' | 'neutral' | 'positive';
    }[]
}

interface NetPromoterScoreChart {
    type: 'netPromoterScore';
    detractors: number;
    passives: number;
    promoters: number;
}

type Chart = KeyTopicsChart | TopicSentimentChart | BarChart | NetPromoterScoreChart;

export interface ReportContent {
    title: string;
    colors: {
        text: string;
        hint: string;
        border: string;
        background: string;
        panel: string;
        brand: string;
        negative: string;
        neutral: string;
        positive: string;
        empty: string;
    }
    executiveSummary: string;
    responseProfile: ResponseProfile;
    actionPlan: ActionPlan;
    scoreIndex: ScoreIndex;
    chartsSectionTitle: string;
    leftColumnCharts: Chart[];
    rightColumnCharts: Chart[];
}

const colorDefinitionsCSS = (reportContent: ReportContent) => {
    return `
        :root {
            --text: ${reportContent.colors.text};
            --hint: ${reportContent.colors.hint};
            --border: ${reportContent.colors.border};
            --background: ${reportContent.colors.background};
            --panel: ${reportContent.colors.panel};
            --brand: ${reportContent.colors.brand};
            --negative: ${reportContent.colors.negative};
            --neutral: ${reportContent.colors.neutral};
            --positive: ${reportContent.colors.positive};
            --empty: ${reportContent.colors.empty};
        }
    `
}

const reportContainer = (reportContent: ReportContent, children: string) => {
    return `
        <html lang="en">
            <head>
                <title>${reportContent.title}</title>
                <style>
                    ${colorDefinitionsCSS(reportContent)}
                    
                    ${REPORT_CSS}
                </style>
            </head>
            <body>
                <div class="main-content">
                    ${children}
                </div>
                ${poweredBy()}
            </body>
        </html>
    `
}

const poweredBy = () => `
    <div class="row" style="color: var(--panel)">
        <div class="column center">
            <div>Powered by <strong>symantiq</strong>_AI</div>
        </div>
    </div>
`;

const formatText = (text: string) => {
    // Anything surrounded in ** is bold
    // Newlines replaced with <br/>
    return text
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
        .replace(/\n/g, '<br/>')
}

const titlePanel = (title: string) => {
    return `
        <div class="panel title row">
            ${title}
        </div>
    `
}

const executiveSummary = (summary: string) => `
        <div class="row panel">
            <div class="column">
                <div class="section-header">Executive Summary</div>
                <div>${formatText(summary)}</div>
            </div>
        </div>
    `

const videoPanel = () => `
    <div class="row panel">
        <div class="column">
            <video controls width="530">
                <source src="./video.mp4" type="video/mp4">
                Your browser does not support the video tag.
            </video>
        </div>
    </div>
`

const actionPlanPanel = (actionPlan: ActionPlan) => `
    <div class="row panel">
        <div class="column">
            <div class="section-header">Action Plan</div>
            <div class="label">${actionPlan.title}</div>
            <div>${formatText(actionPlan.introduction)}</div>
            <div class="horizontal-divider"></div>
            ${actionPlan.sections.map(section => `
                <div class="section-header">${section.title}</div>
                <div class="label">Immediate Actions:</div>
                <ol>
                    ${section.immediateActions.map(action => `
                        <li>
                            <b>${action.title}:</b> ${formatText(action.description)}
                        </li>
                    `).join('')}
                </ol>
                <div class="label">Next-Level Actions:</div>
                <ol start="${section.immediateActions.length + 1}">
                    ${section.nextLevelActions.map(action => `
                        <li>
                            <b>${action.title}:</b> ${formatText(action.description)}
                        </li>
                    `).join('')}
                </ol>
                ${ section.impact ? "<div><b>Impact:</b> ${formatText(section.impact)}</div>" : "" }
                <div class="horizontal-divider"></div>
            `).join('')}
            
            <div class="section-header">Conclusion</div>
            <div>${formatText(actionPlan.conclusion)}</div>
        </div>
    </div>
`

const responseProfilePanel = (responseProfile: ResponseProfile) => {
    const totalResponses = responseProfile.negativeResponses + responseProfile.neutralResponses + responseProfile.positiveResponses;
    const percentPositive = ((responseProfile.positiveResponses / totalResponses) * 100).toFixed(0);
    let percentNeutral = ((responseProfile.neutralResponses / totalResponses) * 100).toFixed(0);
    const percentNegative = ((responseProfile.negativeResponses / totalResponses) * 100).toFixed(0);

    // If the percentages don't add up to 100, give the extra to neutral
    if (parseInt(percentPositive) + parseInt(percentNeutral) + parseInt(percentNegative) < 100) {
        percentNeutral = (parseInt(percentNeutral) + 1).toString();
    }

    return `
        <div class="row panel">
            <div class="column">
                <div class="section-header">Response Profile</div>
                <div class="label">Overall Sentiment</div>
                <div class="sentiment">${responseProfile.overallSentiment}</div>
                <div class="sentiment-bar">
                    <div class="sentiment-bar-negative" style="flex-grow: ${responseProfile.negativeResponses}"></div>
                    <div class="sentiment-bar-neutral" style="flex-grow: ${responseProfile.neutralResponses}"></div>
                    <div class="sentiment-bar-positive" style="flex-grow: ${responseProfile.positiveResponses}"></div>
                </div>
                <div class="sentiment-proportions">
                    <div>${percentNegative}% Negative</div>
                    <div class="vertical-divider"></div>
                    <div>${percentNeutral}% Neutral</div>
                    <div class="vertical-divider"></div>
                    <div>${percentPositive}% Positive</div>
                </div>
            </div>
        </div>
    `;
};

const scoreIndexPanel = (scoreIndex: ScoreIndex) => {
    return `
        <div class="row panel">
            <div class="column">
                <div class="section-header">${scoreIndex.title}</div>
                <div>${formatText(scoreIndex.description)}</div>
                ${scoreIndex.scores.map(score => `
                    <div class="label">${score.title}: ${score.score}/10 <span class="hint">(${score.basedOn})</span></div>
                    <div class="sentiment-bar">
                        <div class="sentiment-bar-${score.color}" style="flex-grow: ${score.score}"></div>
                        <div class="sentiment-bar-empty" style="flex-grow: ${10 - score.score}"></div>
                    </div>
                    <div>
                        ${formatText(score.description)}
                    </div>
                `).join("")}
            </div>
        </div>
    `;
}

const mainColumns = (reportContent: ReportContent) => `
    <div class="row">
        <div class="column">
            ${executiveSummary(reportContent.executiveSummary)}
            ${videoPanel()}
            ${actionPlanPanel(reportContent.actionPlan)}
        </div>
        <div class="column">
            ${responseProfilePanel(reportContent.responseProfile)}
            ${scoreIndexPanel(reportContent.scoreIndex)}
        </div>
    </div>
`

const keyTopicsChart = (chart: KeyTopicsChart) => `
        <div class="panel column">
            <div class="label">Key Topics</div>
            <div>${formatText(chart.description)}</div>
            ${chart.topics.map(topic => `
                <div class="row center">
                    <div style="width: 240px">
                        ${topic.title} (${topic.responses})
                    </div>
                    <div class="sentiment-bar" style="flex-grow: 1">
                        <div class="sentiment-bar-full" style="flex-grow: ${topic.responses}"></div>
                        <div class="sentiment-bar-empty" style="flex-grow: ${chart.totalResponses - topic.responses}"></div>
                    </div>
                </div>
            `).join('')}
        </div>
`

const topicSentimentChart = (chart: TopicSentimentChart) => `
    <div class="panel column">
        <div class="label">Topic by Sentiment</div>
        <div>${formatText(chart.description)}</div>
        ${chart.topics.map(topic => {
            const totalResponses = topic.negativeResponses + topic.neutralResponses + topic.positiveResponses;
            let bar = `<div class="sentiment-bar-empty" style="flex-grow: 1"></div>`
            if (totalResponses > 0) {
                bar = `
                        <div class="sentiment-bar-negative" style="flex-grow: ${topic.negativeResponses}"></div>
                        <div class="sentiment-bar-neutral" style="flex-grow: ${topic.neutralResponses}"></div>
                        <div class="sentiment-bar-positive" style="flex-grow: ${topic.positiveResponses}"></div>
                `
            }
            
            return `
                <div class="row center">
                    <div style="width: 240px">
                        ${topic.title}
                    </div>
                    <div class="sentiment-bar" style="flex-grow: 1">
                        ${bar}
                    </div>
                </div>
            `
        }).join("")}
    </div>  
`

const barChart = (chart: BarChart) => {
    const highestBar = chart.data.reduce((acc, curr) => curr.value > acc ? curr.value : acc, 0);
    const labelStyle = chart.labelStyle || 'horizontal';
    const longestLabel = chart.data.reduce((acc, curr) => curr.label.length > acc ? curr.label.length : acc, 0);
    const marginBottom = labelStyle === 'horizontal' ? 50 : 25 + (longestLabel * 5);

    return `
        <div class="panel column">
            <div class="label">${chart.title}</div>
            <div class="barchart ${labelStyle === 'angled' ? 'barchart-rotate-labels' : ''}" style="margin-bottom: ${marginBottom}px">
                ${chart.data.map(bar => `
                    <div class="barchart-bar-container">
                        <div class="barchart-bar-empty" style="flex-grow: ${highestBar - bar.value}"></div>
                        <div class="barchart-bar-${bar.color}" style="flex-grow: ${bar.value}"></div>
                        <div class="barchart-bar-label">${bar.label}</div>
                    </div>
                `).join('')}
            </div>
        </div>
    `
}

const netPromoterScoreChart = (chart: NetPromoterScoreChart) => {
    const totalResponses = chart.detractors + chart.passives + chart.promoters;
    if (totalResponses === 0) {
        return "No responses to calculate NPS"
    }
    const percentPromoters = (chart.promoters / totalResponses) * 100
    const percentDetractors = (chart.detractors / totalResponses) * 100
    const netPromoterScore = (percentPromoters - percentDetractors);
    const symbol = netPromoterScore > 0 ? '+' : '';
    const formattedNetPromoterScore = symbol + netPromoterScore.toFixed(1);

    return `
        <div class="panel column">
            <div class="label">Net Promoter Score: ${formattedNetPromoterScore}</div>
            <div class="sentiment-bar">
                <div class="sentiment-bar-negative" style="flex-grow: ${chart.detractors}"></div>
                <div class="sentiment-bar-neutral" style="flex-grow: ${chart.passives}"></div>
                <div class="sentiment-bar-positive" style="flex-grow: ${chart.promoters}"></div>
            </div>
            <div class="sentiment-proportions">
                <div>${percentDetractors.toFixed(0)}% Detractors</div>
                <div style="flex-grow: 1"></div>
                <div>${percentPromoters.toFixed(0)}% Promoters</div>
            </div>
        </div>
    `
}

const chart = (chart: Chart) => {
    switch (chart.type) {
        case 'keyTopics':
            return keyTopicsChart(chart);
        case 'topicSentiment':
            return topicSentimentChart(chart);
        case 'bar':
            return barChart(chart);
        case 'netPromoterScore':
            return netPromoterScoreChart(chart);
    }
}

const charts = (reportContent: ReportContent) => `
    <div class="panel column">
        <div class="title row">
            ${reportContent.chartsSectionTitle}
        </div>
        <div class="row" style="align-items: flex-start">
            <div class="column">
                ${reportContent.leftColumnCharts.map(c => chart(c)).join('')}
            </div>
            <div class="column">
                ${reportContent.rightColumnCharts.map(c => chart(c)).join('')}
            </div>
        </div>
    </div>
`;

export const buildReport = (reportContent: ReportContent) => {
    return reportContainer(reportContent, `
        ${titlePanel(reportContent.title)}
        ${mainColumns(reportContent)}
        ${charts(reportContent)}
    `)
}